body {
    background: #f9f9f9;
    color: #222;
    font-size: 1.1rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin: 0;
}

a {
    color: black;
    text-decoration: underline;

    &:visited,
    &:active {
        color: black;
        text-decoration: underline;
    }

    &:hover {
        color: #666;
    }
}

h1+p,
h2+p,
h3+p {
    margin-top: -20px;
}


.aboveFooter {
    height: 60px;
    background: #0d2847;
    border-top: 12px solid #e9eaee;
}

.footer {
    height: 100px;
    background: #282a2b;
    border-top: 1px solid #4b4c4d;

    div {
        line-height: 100px;
        color: #ccc;
        font-size: 12px;
        padding: 0 10px;
        overflow: hidden;

        a {
            color: #ccc;
            text-decoration: none;
            margin: 0 10px;
        }

        .copyright {
            float: left;
        }

        .terms {
            float: right;
        }
    }
}

#mainContent {
    background: #fafafa;
    max-width: 1000px;
    min-height: 900px;
    padding: 20px;
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    @media(max-width: 480px) {
        padding: 5px;
    }
}

#notfound {
    text-align: center;

    h1 {
        color: #aaa;
        font-size: 6em;
    }

    p {
        font-style: italic;
        font-size: 16px;
        margin-bottom: 60px;
    }

    a {
        display: block;
        margin-top: 10px;
        margin-bottom: 20px;
    }
}

.only-big {
    display: none;
}

@media (min-width: 650px) {
    .only-big {
        display: inline;
    }
}


.make-them-see {
    font-size: 3em;
    margin-bottom: 40px;

    @media (max-width: 550px) {
        font-size: 2em;
    }

}

table.summary {
    width: 600px;
    margin: 20px auto;

    tr {
        height: 20px;
        margin: 0;

        td {
            &:first-child {
                font-weight: bold;
                width: 150px;
            }

            margin: 0;
            font-size: 2rem;
        }
    }
}

@import 'forms.scss';
@import 'options.scss';
@import 'selection.scss';
@import 'nav.scss';
input {
    cursor: pointer;
}

form {
    .form-group {
        display: block;
        height: 75px;
        margin: 3px 0 20px 0;
        padding: 0 5px;

        &.required label:after {
            content: '*';
            color: red;
        }

        label {
            display: block;
            margin-bottom: 2px;
            font-size: 24px;

            @media (max-width: 480px) {
                font-size: 20px;
            }
        }

        .label-note {
            font-size: 15px;
        }

        input[type=text],
        input[type=password],
        input[type=email] {
            font-size: 16px;
            padding: 4px;
            min-width: 440px;
            box-sizing: border-box;
            border: 1px solid #aaa;

            @media (max-width: 480px) {
                min-width: 0;
                width: 100%;
            }
        }

        &.error input,
        &.error select,
        &.error textarea {
            border: 1px solid red;
        }
    }

}

.submit {
    text-align: right;

    button {
        font-size: 24px;
        margin: 20px 0;
    }
}

div.paid {
    margin: 20px;
    font-weight: bold;
    text-align: center;
    font-size: 24px;
    color: green;
}

div.error {
    color: red;
    overflow: visible;
}

button,
.registration-success .button {
    cursor: pointer;
    text-decoration: none;
    font-weight: normal;
    display: inline-block;
    margin: 10px 0 0 0;
    font-size: 24px;
    background: #d2ff52;
    background: #236dc1;
    border: 2px solid #236dc1;
    padding: 5px 15px;
    border-radius: 2px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    color: white;

    &:disabled {
        background: #ccc !important;
        border: 2px solid #ccc !important;
        cursor: default !important;
    }

    &:active {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }

    &:hover {
        background: #3175c4;
        border: 2px solid #3175c4;
    }

    &:focus {
        outline: none;
        border: 2px solid #0d2847;
    }
}


input[type=checkbox].css-checkbox {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;

    &+label.css-label {
        display: inline-block;
        line-height: 30px;
        font-size: 30px;
        cursor: pointer;

        &:before {
            content: '';
            width: 30px;
            margin-right: 3px;
            height: 30px;
            display: inline-block;
            line-height: 30px;
            background-repeat: no-repeat;
            background-position: 0 0;
            font-size: 30px;
            vertical-align: -5px;
            cursor: pointer;
        }
    }

    &:checked+label.css-label:before {
        background-position: 0 -30px;
    }
}

label.css-label:before {
    background-image: url('../content/checkbox.png');
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.form {

    img.headerImage {
        width: 100%;
    }
}

.signInMethods {
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    li {
        display: block;
        width: 140px;
        height: 160px;
        margin: 20px;
        padding: 10px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        background: white;

        &:hover {
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        }

        a {
            width: 140px;
            height: 140px;
            display: block;
            margin: 0;
            text-align: center;
            font-size: 12px;
            text-decoration: none;

            img {
                max-width: 140px;
                max-height: 140px;
            }
        }
    }
}

.confirmation-buttons {
    display: flex;
    justify-content: space-between;
}

.registration-success {
    text-align: center;
    padding: 50px 0;

    img {
        width: 50px;
        height: 50px;
        margin: 15px;
    }

    h1 {
        text-align: center;
    }

    a {
        display: block;
        margin: 10px 0;
    }
}

.children-list {
    margin-bottom: 20px;
    padding-bottom: 10px;

    @media (max-width: 850px) {
        border-top: 2px solid #666;
        border-bottom: 2px solid #666;
    }

    .child-container {
        padding-bottom: 20px;


        @media (max-width: 850px) {
            &:first-child {
                border-top: none;
            }

            &:hover {
                background: rgba(255, 255, 255, 0.8);
            }

            border-top: 1px solid #666;
        }

        .child {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            border: 1px solid rgba(0, 0, 0, 0.3);

            .form-group {
                margin-bottom: 0;
            }

            &>div {
                height: auto;
            }

            div {
                box-sizing: border-box;
            }

            &>div input[type=text] {
                min-width: initial;
                width: 100%;
                height: 32px;
            }

            .child-name {
                width: 300px;
            }

            .child-age {
                width: 120px;
            }

            .child-slots {
                width: 270px;
                padding-bottom: 10px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                label {
                    padding-left: 100px;
                    display: flex;
                    font-size: 0.8em;

                    span.child-day {
                        display: block;
                        width: 40px;
                        text-align: center;
                    }
                }

                .child-slots-container {
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: column;

                    &>div {
                        display: flex;
                        justify-content: flex-start;
                        flex-direction: row;

                        .child-slot {
                            width: 100px;
                            font-size: 0.8em;
                            line-height: 16px;
                        }

                        .child-day {
                            width: 40px;
                            height: 16px;

                            input[type=checkbox] {
                                width: 12px;
                                height: 12px;
                                display: block;
                                margin: 2px auto;
                            }
                        }

                    }
                }
            }

            .child-controls {
                line-height: 75px;
            }
        }
    }
}

table.childcare-table {

    td,
    th {
        border: 1px solid black;
        margin: 0;
        border-collapse: collapse;
        text-align: left;
        padding: 4px;
    }
}

.child-summary {
    padding-left: 20px;
}
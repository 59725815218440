
ul.conference-items {
    list-style: none;
    margin: 0;
    padding: 0;
    border-top: 2px solid #333;
    border-bottom: 2px solid #333;

    ul.suboptions {
        list-style: none;
        margin: 10px;
        padding: 0;

        & > li {
            margin-bottom: 10px;

            label, input {
                cursor: pointer;
            }
        }
    }

    & > li {
        border-top:1px solid #666;
        padding: 3px 1px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        @media (max-width: 400px) {
            font-size:14px;
        }

        &:hover {
            background-color: rgba(255,255,255,0.5);
        }

        &:first-child {
            border-top: none;
        }
        
        .title {
            font-weight: bold;
        }
        
        .subtitle {
            font-size: 0.9em;
            color: #666;
        }

        .date {
            font-style: italic;
        }

        .cost {
            font-weight: bold;
            label {
                font-weight: normal;
            }
        }

        .selected {
            width: 120px;

            @media (max-width: 480px) {
                width: 35px;
                label.css-label {
                    width: 30px;
                    color:rgba(0,0,0,0);
                    overflow:hidden;
                    vertical-align: middle;
                    height: 30px;
                    padding: 0;
                    line-height: 0;
                }
            }
        }
    }
}

.conference-items-display {
    list-style:none;
    padding: 0;
    margin: 0 0 0 20px;

    li {
        margin: 6px 0;

        .title {
            font-weight: bold;
        }

        .subtitle {
            font-size: 0.9em;
        }

        .date {
            font-size: 0.8em;
            color: #666;
        }

        .cost {
            font-size: 0.8em;
            font-weight: bold;

            label {
                font-weight: normal;
            }
        }
    }
}

.total {
    font-size: 24px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
        font-weight: normal;
    }
}